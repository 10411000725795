import { Button } from "react-bootstrap";
import {  handleRedirect } from "../../utils/functions";
import { appURL } from "../../config/key";

const ConsultingDetailSection = ({ singleConsulting }) => {
  return (
    <div className="consulting-detail-section">
      <div className="container">
        <div className="consulting-detail-grid">
          <div className="consulting-detail-left">
            <ul>
              <h6>סיבות פנייה נפוצות</h6>
              {singleConsulting?.relevantCases?.map((el, i) => (
                <li key={singleConsulting?.id + i}>{el}</li>
              ))}
            </ul>
          </div>
          <div className="consulting-detail-right">
            <h2>{singleConsulting?.subtitle}</h2>
            <p>{singleConsulting?.description}</p>
            <Button
              className="black-btn"
              onClick={() => {
                handleRedirect(
                  `${appURL}/meetingrequest${
                    singleConsulting && singleConsulting !== "null"
                      ? `?expertise=${singleConsulting?.title}`
                      : ""
                  }`
                );
              }}
            >
              שלח/י בקשה לייעוץ
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultingDetailSection;
