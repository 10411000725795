export const handleCheckUrl = (getUrl) => {
  let url = window.location.search.includes("?");
  if (url) {
    window.location.href = `${getUrl}/${window.location.search}`;
  } else {
    window.location.href = getUrl;
  }
};

export const scrollToRxContact = () => {
  let contact = document.getElementById("rx-contact-section");
  if (contact) {
    contact.scrollIntoView({ top: 0, behavior: "smooth" });
  } else {
    window.location.href = "/#rx-contact-section";
  }
};

export const handleRedirect = (getUrl) => {
  window.location.href = getUrl;
}
